import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { variables } from '../../config/Variables'

export const PasswordRenew = () => {

    const locationUrl = useLocation();
    const queryParams = new URLSearchParams(locationUrl.search);

    const navigate = useNavigate();
    const [KamaSifre, sifreChange] = useState("");
    const [KamaSifreTekrar, sifreTekrarChange] = useState("");
    const QKey = queryParams.get("k")



    const handleSubmit = (e) => {

        e.preventDefault();

        if (IsValidate()) {

            fetch(variables.API_URL + 'Login/ChangePassword',
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'content-type': 'application/json' },
                    body: JSON.stringify({
                        Sifre: KamaSifre,
                        ForgotPasswordKey: QKey
                    })
                })
                .then(res => res.json())
                .then((res) => {
                    console.log("RES:" + res)
                    if (res === "OK") {
                        toast('Şifreniz Başarıyla Güncellendi, Giriş yapmayı deneyebilirsiniz.')
                        navigate('/login')
                    } else {
                        toast('Şifre değiştirilemiyor ' + res)
                    }
                }).catch((err) => {
                    toast.error('Şifre değişikiği veritabanına yazılamadı: ' + err.message)
                })
        }
    }

    const IsValidate = () => {
        let isProceed = true;
        let errorMessage = 'Lütfen gerekli alanları doldurun; ';
        if (KamaSifre.length < 6) {
            isProceed = false;
            errorMessage += 'Şifre uzunluğu en az 6 karakter, en çok 12 karakter olabilir ';
        }

        if (KamaSifre.length > 12) {
            isProceed = false;
            errorMessage += 'Şifre uzunluğu en az 6 karakter, en çok 12 karakter olabilir ';
        }

        if (KamaSifreTekrar === null || KamaSifreTekrar === '') {
            isProceed = false;
            errorMessage += 'Şifre Tekrar Alanı ';
        }

        if (KamaSifre === null || KamaSifre === '') {
            isProceed = false;
            errorMessage += 'Şifre Alanı';
        }



        if (!isProceed) {
            toast.warning(errorMessage)
        } else {
            if (KamaSifre != KamaSifreTekrar) {
                isProceed = false;
                toast.warning('Şifreler Uyuşmuyor, tekrar deneyiniz');
            }
        }
        return isProceed;
    }

    return (

        <div>
            <div className="">
                <form className="container" onSubmit={handleSubmit}>
                    <div className="offset-lg-3 col-lg-6" style={{ marginTop: '100px' }}>
                        <div className="card">
                            <div className="card-header">
                                <h1>Şifre Yenileme</h1>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div>
                                        <div >
                                            <div className="form-group">
                                                <label>Şifre <span className="errmsg">*</span></label>
                                                <input value={KamaSifre} onChange={e => sifreChange(e.target.value)} type="password" className="form-control"></input>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="form-group">
                                                <label>Tekrar giriniz <span className="errmsg">*</span></label>
                                                <input value={KamaSifreTekrar} onChange={e => sifreTekrarChange(e.target.value)} type="password" className="form-control"></input>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" className="btn btn-primary me-2 btn-sm">Kaydet</button>
                                <Link to={'/login'} className="btn btn-danger btn-sm">Vazgeç</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div >

        </div >
    );

}
