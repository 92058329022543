import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {

    tr: {
        translation: {
            baslikAnaSayfa: 'Kama 3D Doküman Merkezi',
            menuAnaSayfa: 'Ana Sayfa',
            menuHakkimizda: 'Hakkımızda',
            menuUyeOl: 'Üye Ol',
            menuSifremiUnuttum: 'Şifremi Unuttum',
            menuSifremiYenile: 'Şifremi Yenile',
            menuAktivasyon: 'Aktivasyon',
            menuGiris: 'Giriş',
            menuUrunDosyalari: 'Ürün Dosyaları',
            menuBilgilerim: 'Bilgilerim',
            baslikHakkimizda: 'Hakkımızda',
            metinHakkimizda1: '1956 yılında otomotiv yan sanayi ve kalıp imalatı ile başlayan KAMA firmasının ticaret hayatı 1967 yılında teker imalatına başlaması ile yön değiştirmiştir. 1970 senelerinin başlarında 8-10 kişi ile ve 200 m² lik bir atölye, günümüzde 400 kişilik bir kadro ve 25000 m² kapalı alanda faaliyet gösteren Türkiye de konusunda lider bir Teker fabrikasına dönüşmüştür.',
            metinHakkimizda2: 'Günümüz teknolojilerini yakından takip eden firmamız yurt içinde lider konumunu muhafaza ederek yurt dışında da başta İngiltere, Rusya, Almanya olmak üzere toplam 22 ülkede satış noktaları mevcuttur.',
            baslikHosgeldiniz: 'Kama 3D Doküman Merkezine Hoşgeldiniz!',
            baslikGiris: 'Kullanıcı Girişi',
            mesajHosgeldiniz: 'Kama ürünlerine ait teknik dokümanlarına giriş yaptıktan sonra "Ürün Dosyaları" menüsünden ulaşabilirsiniz.',
            mesajHosgeldiniz1: 'Teknik Dokümanlar eklenmeye devam etmektedir.',
            mesajUrunBulunamadi: 'Ürün Bulunamadı',
            mesajUrunBulundu: ' adet ürün bulundu.',
            mesajUrunIlkListe: 'Son eklenen ürünler gösteriliyor. ',
            mesajUrunIlkListe1: ' adet kayıt.',
            mesajOturumZamanAsimi: 'Oturumunuz zaman aşımına uğradı, lütfen tekrar giriş yapınız',
            butonAra: 'Ara',
            aranacakMetin: 'Ürün Ara',
            sadece3D : 'Sadece 3 Boyutlu Modeller',
            tabloBaslikResim: 'Ürün Resmi',
            tabloBaslikUrunKodu: 'Ürün Kodu',
            tabloBaslikAciklama: 'Açıklama',
            tabloBaslikPdfOnizleme: 'PDF Önizleme',
            tabloBaslikPdfDosya: '.PDF Dosyası',
            tabloBaslikStepDosya: '.STEP Dosyası',
            tabloBaslikModelDosya: '3D Görünüm',
            tabloBaslikEklenmeTarihi: 'Eklenme Tarihi',
            butonKapat: 'Kapat',
            mesajBilgilerinizGuncellendi: 'Bilgileriniz Güncellendi.',
            mesajUyelikBasarili: 'Kayıt Başarılı, Lütfen e-posta adresinizi kontrol ediniz.',
            mesajKayitYapilamiyor: 'Kayıt Yapılamıyor:',
            mesajUyelikBosAlanlar: 'Lütfen gerekli alanları doldurun; ',
            mesajSifreUzunluguUyari: 'Şifre uzunluğu en az 6 karakter, en çok 12 karakter olabilir ',
            mesajFirmaAdiUyari: 'Firma adını giriniz(en az 3 karakter)',
            mesajTelefonUyari: 'Telefon Giriniz',
            mesajEPostaUyari: 'Lütfen geçerli bir Eposta adresi giriniz.',
            mesajEpostaEksik: 'Lütfen e-posta adresinizi giriniz.',
            formUyelikBaslikYeni: 'Kullanıcı Kaydı',
            formUyelikBaslikDuzenle: 'Bilgilerimi Düzenle',
            formUyelikEposta: 'E-Posta',
            formUyelikSifre: 'Şifre',
            formUyelikAdSoyad: 'Adınız Soyadınız',
            formUyelikFirmaAdi: 'Firma Adı',
            formUyelikBolum: 'Bölüm',
            formUyelikWebSitesi: 'Varsa Web Siteniz',
            formUyelikTelefon: 'Telefon',
            butonGuncelle: 'Güncelle',
            butonKayitOl: 'Kayıt Ol',
            butonVazgec: 'Vazgeç',
            mesajSifremiUnuttumMail: 'Şifre sıfırlama e-posta adresinize gönderildi, lütfen e-posta adresinizi kontrol ediniz.',
            mesajSunucuTarafiHatasi: 'Sunucu tarafında sorunla karşılaşıldı',
            baslikSifremiUnuttum: 'Şifremi Unuttum',
            butonDevam: 'Devam',
            butonGeri: 'Geri',
            butonGiris: 'Giriş',
            butonSifremiUnuttum: 'Şifremi Unuttum',
            butonYeniKullanici: 'Hesap Oluştur',
            butonCikis: 'Güvenli Çıkış',
            logoKama: 'logo-kama.png',
            mesajGirisHatali: 'E-posta adresi veya şifre yanlış',
            mesajAktivasyonBasarili : 'Aktivasyon Başarılı.',
            mesajAktivasyonSayfasi: 'Aktivasyon başarılı, giriş yapmak için aşağıdaki Devam butonuna tıklayınız',
            mesajAktivasyonHatali: '<strong>Aktivasyon gerçekleşmedi</strong>, kod bulunamadı, Lütfen <strong>KAMA Satış Bölümü</strong> ile irtibata geçiniz.',
            menu3dView : '3D Görünüm',
            productGroup : 'Ürün Gupları'
        }
    },
    en:
    {
        translation: {
            baslikAnaSayfa: 'Kama 3D Document Center',
            menuAnaSayfa: 'Home Page',
            menuHakkimizda: 'About Us',
            menuUyeOl: 'Create Account',
            menuSifremiUnuttum: 'Forgot Password',
            menuSifremiYenile: 'Change Password',
            menuAktivasyon: 'Activation',
            menuGiris: 'Sign In',
            menuUrunDosyalari: 'Product Files',
            menuBilgilerim: 'Edit Account',
            baslikHakkimizda: 'About Us',
            metinHakkimizda1: 'Trading life of the Company KAMA, which had began with the automotive sub-industry and mold manufacturing in 1956, changed it\'s direction in 1967 with start of the manufacturing of castors and wheels. Today, it has became a leader castor and wheel factory that operates with a staff of 400 people and in a closed area 25000 m² in Turkey.',
            metinHakkimizda2: 'The Company, closely following today\'s technologies, maintains its leading position in the country and includes a total of 22 sales outlets at abroad mainly in England, Russia and Germany.',
            baslikHosgeldiniz: 'Welcome to Kama 3D Document Center!',
            baslikGiris: 'Login',
            mesajHosgeldiniz: 'You can access the technical documents of Kama products from the "Product Files" menu after logging in.',
            mesajHosgeldiniz1: 'Technical Documents continue to be added.',
            mesajUrunBulunamadi: 'Product not Found',
            mesajUrunBulundu: ' product(s) found',
            mesajUrunIlkListe: 'Showing recently added. ',
            mesajUrunIlkListe1: ' records.',
            mesajOturumZamanAsimi: 'Your session has timed out, please log in again.',
            butonAra: 'Search',
            aranacakMetin: 'Search Product',
            sadece3D: 'Only 3D',
            tabloBaslikResim: 'Product Image',
            tabloBaslikUrunKodu: 'Product Code',
            tabloBaslikAciklama: 'Description',
            tabloBaslikPdfOnizleme: 'PDF Preview',
            tabloBaslikPdfDosya: '.PDF File',
            tabloBaslikStepDosya: '.STEP File',
            tabloBaslikModelDosya: '3D View',
            tabloBaslikEklenmeTarihi: 'Upload Date',
            butonKapat: 'Close',
            mesajBilgilerinizGuncellendi: 'Your Account has been updated.',
            mesajUyelikBasarili: 'Membership Successful, please check your e-mail address.',
            mesajKayitYapilamiyor: 'Unable the Register:',
            mesajUyelikBosAlanlar: 'Please fill in the required fields; ',
            mesajSifreUzunluguUyari: 'Password length can be at least 6 characters and at most 12 characters.',
            mesajFirmaAdiUyari: 'Enter the company name (at least 3 characters)',
            mesajTelefonUyari: 'Enter Phone Number',
            mesajEPostaUyari: 'Please enter a valid E-Mail address.',
            mesajEpostaEksik: 'Please enter your E-Mail address',
            formUyelikBaslikYeni: 'Create Account',
            formUyelikBaslikDuzenle: 'Edit Account',
            formUyelikEposta: 'E-Mail',
            formUyelikSifre: 'Password',
            formUyelikAdSoyad: 'Full Name',
            formUyelikFirmaAdi: 'Company Name',
            formUyelikBolum: 'Department',
            formUyelikWebSitesi: 'Company Web Site (if exist)',
            formUyelikTelefon: 'Phone',
            butonGuncelle: 'Update',
            butonKayitOl: 'Create Account',
            butonVazgec: 'Cancel',
            mesajSifremiUnuttumMail: 'Password reset has been sent to your e-mail address, please check your e-mail address.',
            mesajSunucuTarafiHatasi: 'A problem was encountered on the server:',
            baslikSifremiUnuttum: 'Forgot Password',
            butonDevam: 'Continue',
            butonGeri: 'Back',
            butonGiris: 'Login',
            butonSifremiUnuttum: 'Forgot Password',
            butonYeniKullanici: 'Create Account',
            butonCikis: 'Logout',
            logoKama: 'logo-kama-en.png',
            mesajGirisHatali: 'Email address or password is incorrect',
            mesajAktivasyonBasarili: 'Activation successful.',
            mesajAktivasyonSayfasi: 'Activation successful, click the Continue button below to log in.',
            mesajAktivasyonHatali: '<strong>Activation failed</strong>, activation code not found, Please contact <strong>KAMA Sales Department</strong>.',
            menu3dView: '3D View',
            productGroup: 'Product Groups'
        }
    }
}

i18n
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem('siteLang') ? localStorage.getItem('siteLang') : 'tr',
        resources
    })

export default i18n;
