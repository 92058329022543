
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { variables } from "../../config/Variables";
import { toast } from "react-toastify";

import { useState, useEffect } from "react";

import { Trans, useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from "i18next";

const Activation = () => {

    const adreseGit = useNavigate();
    const locationUrl = useLocation();

    const [activationSuccess, successStatus] = useState(false);

    //console.log(locationUrl)
    const queryParams = new URLSearchParams(locationUrl.search);
    const singleValue = queryParams.get('activation');
    //console.log("Component Render oldu")

    useEffect(() => {
        //console.log("Sunucuya gidiyoruz")
        if (singleValue) {
            fetch(variables.API_URL + 'user/activationUser/activate',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        activationCode: singleValue
                    })
                })
                .then(res => res.json())
                .then((result) => {
                    console.log("sonuç:" + result)
                    if (result === "Aktivasyon OK") {
                        toast(t('mesajAktivasyonBasarili'));
                        successStatus(true);
                        //adreseGit("/Login")
                    } else {
                        toast.warning(result)
                        successStatus(false);
                    }
                })
        }
        //console.log("ve geliyoruz")
    }, [])

    //console.log("Single Value:" + singleValue)

    return (
        <div className="App">
            <div className="row">
                <div className="offset-lg-3 col-lg-6" style={{ marginTop: '100px' }}>
                    {
                        activationSuccess == true ?
                            <div>
                                <div className="alert alert-success">{t('mesajAktivasyonSayfasi')} </div>
                                <button className="btn btn-primary btn-sm" onClick={() => { adreseGit("/Login") }}>{t('butonDevam')}</button>
                            </div>
                            :
                            <div className="alert alert-danger">
                                <Trans>
                                    {t('mesajAktivasyonHatali')}
                                </Trans>
                            </div>
                    }
                </div>
            </div>

        </div >
    )
}

export default Activation;