import { About } from '../pages/About'
import { Home } from '../pages/Home'
import { Login } from '../pages/Login'
import { ProductList } from '../pages/ProductList'
import { ForgotPassword } from '../pages/ForgotPassword'
import { AddMember } from '../pages/AddMember'
import { PasswordRenew } from '../pages/PasswordRenew'
import { MyAccount } from '../pages/MyAccount'
import Activation from '../pages/Activation'
import {ProductGroup} from'../pages/ProductGroup'
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from "i18next";

export const nav = [
    { path: "/", name: t('menuAnaSayfa'), element: <Home />, isMenu: true, isPrivate: false },
    { path: "/login", name: t('menuGiris'), element: <Login />, isMenu: false, isPrivate: false },
    { path: "/about", name: t('menuHakkimizda'), element: <About />, isMenu: true, isPrivate: false },
    { path: "/productlist", name: t('menuUrunDosyalari'), element: <ProductList />, isMenu: true, isPrivate: true },
    { path: "/myaccount", name: t('menuBilgilerim'), element: <MyAccount />, isMenu: true, isPrivate: true },
    { path: "/addmember", name: t('menuUyeOl'), element: <AddMember />, isMenu: false, isPrivate: false },
    { path: "/forgotpassword", name: t('menuSifremiUnuttum'), element: <ForgotPassword />, isMenu: false, isPrivate: false },
    { path: "/passwordrenew", name: t('menuSifremiYenile'), element: <PasswordRenew />, isMenu: false, isPrivate: false },
    { path: "/activation", name: t('menuAktivasyon'), element: <Activation />, isMenu: false, isPrivate: false }
    //{ path: "/ProductGroup", name: t('productGroup'), element: <ProductGroup />, isMenu: true, isPrivate: false }
]