import React, { Component, useRef } from "react";
import { variables } from "../../config/Variables";
import { toast } from "react-toastify";
import stepIcon from "./stepIcon.png"
import pdfIcon from "./pdfIcon.png"
import ucdIcon from "./3dIcon.png"
import schemaIcon from "./schemaIcon.png"
import axios from "axios"
import { t } from "i18next";
import IFrameWindow from "../structure/IFrameWindow";


export class ProductList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productGroups: [],
            groupCode: '*',
            rootGroupCode: '',
            sortOrder: "ASC",
            products: [],
            aranacakMetin: '*',
            model3bCheck: '0',
            UserId: 0,
            UrunId: 0,
            DosyaAdi: "",
            productUrl: "",
            detailList: "no",
            FilePath: variables.FILE_URL,
            ImagePath: variables.IMAGE_URL,
            SchemaPath: variables.SCHEMA_URL
        }
    }

    sorting(columnName) {
        if (this.state.sortOrder === "ASC") {
            const sortedList = [...this.state.products].sort((a, b) => a[columnName] > b[columnName] ? 1 : -1);
            this.setState({ products: sortedList });
            this.setState({ sortOrder: "DSC" });
        }
        if (this.state.sortOrder === "DSC") {
            const sortedList = [...this.state.products].sort((a, b) => a[columnName] < b[columnName] ? 1 : -1);
            this.setState({ products: sortedList });
            this.setState({ sortOrder: "ASC" });
        }
    }

    refreshList() {
        this.setState({ detailList: 'yes' })
        if (this.state.aranacakMetin != '') {
            fetch(variables.API_URL + 'productlist/' + this.state.aranacakMetin + "/" + this.state.model3bCheck, {
                headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('jwtKey') }
            })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error(response.status)
                    }
                    else {
                        return response.json();
                    }
                    //return Promise.reject(response);
                })
                .then(data => {
                    //toast('Kayıtlar geldi')
                    if (data.length == 0) { toast(t('mesajUrunBulunamadi')) } else { toast(data.length + t('mesajUrunBulundu')) }
                    //console.log(data)
                    this.setState({ products: data });
                })
                .catch((response) => {
                    toast.warning(t('mesajOturumZamanAsimi'))
                    const link = document.createElement('a');
                    link.href = "/login";
                    document.body.appendChild(link);
                    link.click();
                })
        }
    }

    refreshGroups() {
        this.setState({ detailList: 'no' })
        //console.log(variables.API_URL + 'ProductGroup/' + this.state.groupCode)
        fetch(variables.API_URL + 'ProductGroup/' + this.state.groupCode, {
            headers: {
                'authorization': 'Bearer ' + sessionStorage.getItem('jwtKey')
            }
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.status)
                }
                else {
                    return response.json();
                }
                //return Promise.reject(response);
            })
            .then(data => {
                if (data.length == 0) { toast(t('mesajUrunBulunamadi')) }
                this.setState({ productGroups: data });
                //console.log(data)
            })
            .catch((response) => {
                toast.warning(t('mesajOturumZamanAsimi'))
                const link = document.createElement('a');
                link.href = "/login";
                document.body.appendChild(link);
                link.click();
            })

    }

    downloadPdfFile(product) {

        var url = variables.API_URL + 'DownloadFile/' + product.PdfFile

        axios({
            url: url,
            method: 'POST',
            responseType: 'blob',
            data: JSON.stringify({
                DosyaAdi: product.PdfFile,
                UserId: sessionStorage.getItem("activationKey"),
                UrunId: product.Id
            }),
            headers: { 'Accept': 'application/pdf', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('jwtKey') }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var dosyaAdi = product.PdfFile
            link.setAttribute('download', dosyaAdi);
            document.body.appendChild(link);
            link.click();
        });
    }


    downloadStepFile(product) {
        var url = variables.API_URL + 'DownloadFile/' + product.StepFile

        axios({
            url: url,
            method: 'POST',
            responseType: 'blob',
            data: JSON.stringify({
                DosyaAdi: product.StepFile,
                UserId: sessionStorage.getItem("activationKey"),
                UrunId: product.Id
            }),
            headers: { 'Accept': 'application/step', 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('jwtKey') }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var dosyaAdi = product.StepFile
            link.setAttribute('download', dosyaAdi);
            document.body.appendChild(link);
            link.click();
        });

    }

    changeAranacakMetin = (e) => {
        this.setState({ aranacakMetin: e.target.value });
    }

    changeModel3b = (e) => {
        this.setState({ model3bCheck: e.target.checked });
        {
            e.target.checked ? this.setState({ model3bCheck: "1" }) : this.setState({ model3bCheck: "0" })
        }
        //console.log(this.state.model3bCheck)
    }

    changeGroupCode = (e) => {
        this.setState({ groupCode: e }, () => {
            if (e == "*" || e=="") { this.setState({ aranacakMetin: '*' }) };
            if (e.split("-").length == 2) {
                this.setState({ aranacakMetin: e }, () => { this.refreshList() });
                const rootCode = e.split("-")
                this.setState({ rootGroupCode: rootCode[0] })
            } else {
                this.setState({ rootGroupCode: '' })
                this.refreshGroups();
            }
        });
    }
    searchClick() {
        this.setState({ rootGroupCode: '' })
        this.refreshList();
    }

    showPopUpSchema(urunObj) {
        this.setState({ imgSchema: variables.SCHEMA_URL + urunObj.SchemaFile })
        this.setState({ OrjinalKodu: urunObj.OriginalKodu })
    }

    showPopUpImg(urunObj) {
        this.setState({ imgProduct: variables.IMAGE_URL + urunObj.ImageFile })
        this.setState({ OrjinalKodu: urunObj.OriginalKodu })
    }

    showPopUp3d(pUrl) {
        this.setState({ productUrl: pUrl })
    }

    componentDidMount() {
        this.refreshGroups();
    }

    render() {
        const {
            productGroups,
            groupCode,
            ImagePath,
            products,
            aranacakMetin,
            productUrl
        } = this.state;

        return (
            // GRUP LİSTESİ
            <div>
                <div className="container">
                    <div className="clearfix border-bottom" style={{ height: 90 }}>
                        <div className="float-start w-50 className">
                            <div className="align-self-end"><div style={{height:60}}></div><a href="#" onClick={() => this.changeGroupCode('*')}>Ana Ürün Grupları</a>
                                {
                                    this.state.groupCode.split("-").length == 1 ?
                                        <span className="p-1"> / {this.state.groupCode}</span> : null
                                }
                                {
                                    this.state.rootGroupCode != "" ?
                                        <span className="p-1">
                                            / <a href="#" onClick={() => this.changeGroupCode(this.state.rootGroupCode)}>{this.state.rootGroupCode}</a>&nbsp;
                                            / {this.state.groupCode}
                                        </span>
                                        : null
                                }
                            </div>
                        </div>

                        <div className="float-end w-25">
                            <div className="d-flex mt-3">
                                <input id="txtSearch" placeholder={t('aranacakMetin')} className="form-control" value={aranacakMetin} onChange={this.changeAranacakMetin}></input>
                                <button type="button"
                                    className="btn btn-primary pl-2"
                                    onClick={() => this.searchClick()}>{t('butonAra')}
                                </button>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={this.changeModel3b} />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    {t('sadece3D')}
                                </label>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.detailList == "no" ?
                            <div className="text-center">
                                <div className="container-fluid d-flex">
                                    <div className="container">
                                        <div className="row">
                                            {productGroups.map(prdGrp =>
                                                <div className="col-sm-2 p-0 mt-2" key={prdGrp.GrupKodu}>
                                                    <div className="shadow card" style={{ width: 200 }}>
                                                        <div className="card-header">{prdGrp.KategoriAdi}</div>
                                                        <div style={{ height: 150 }}>
                                                            <a href="#" onClick={() => this.changeGroupCode(prdGrp.GrupKodu)}><img className="card-img-top  img-fluid mt-2" src={ImagePath + prdGrp.ImageFile} alt="Card image" style={{ width: 120 }} /></a>
                                                        </div>
                                                        <div className="card-body">
                                                            <h3 className="card-title productCode bg-white bg-opacity-75">
                                                                {prdGrp.GrupKodu}
                                                            </h3>
                                                            <div className="d-flex justify-content-center"><a href="#" onClick={() => this.changeGroupCode(prdGrp.GrupKodu)} className="btn btn-primary">{prdGrp.UrunSayisi} adet Ürün</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </div>

                <div className="container">
                {
                    this.state.detailList == "yes" ?
                        <div className="container mt-3">
                            <div className="text-center">
                                <table className="table table-light table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th>{t('tabloBaslikResim')}</th>
                                            <th style={{ cursor: 'pointer' }} onClick={() => this.sorting("OriginalKodu")}>{t('tabloBaslikUrunKodu')}</th>
                                            <th style={{ cursor: 'pointer' }} onClick={() => this.sorting("KatalogKodu")}>{t('tabloBaslikAciklama')}</th>
                                            <th>{t('tabloBaslikPdfOnizleme')}</th>
                                            <th>{t('tabloBaslikPdfDosya')}</th>
                                            <th>{t('tabloBaslikStepDosya')}</th>
                                            <th>{t('tabloBaslikModelDosya')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map(prd =>
                                            <tr className="align-middle" key={prd.Id}>
                                                <td>
                                                    <button className="btn" data-bs-toggle="modal" data-bs-target="#imgPopUp" onClick={() => this.showPopUpImg(prd)}>
                                                        <img src={ImagePath + prd.ImageFile} width="75" />
                                                    </button>
                                                </td>
                                                <td>{prd.OriginalKodu}</td>
                                                <td>{prd.KatalogKodu}</td>
                                                <td>
                                                    {
                                                        prd.SchemaFile ?
                                                            <button className="btn" data-bs-toggle="modal" data-bs-target="#schemaPopUp" onClick={() => this.showPopUpSchema(prd)}>
                                                                <img width={64} src={schemaIcon} />
                                                            </button>
                                                            : null
                                                    }
                                                </td>

                                                <td>
                                                    {
                                                        prd.PdfFile ?
                                                            <button className="btn" onClick={() => this.downloadPdfFile(prd)}>
                                                                <img width="64" src={pdfIcon} />
                                                            </button>
                                                            : null
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        prd.StepFile ?
                                                            <button className="btn" onClick={() => this.downloadStepFile(prd)}>
                                                                <img width={64} src={stepIcon} />
                                                            </button>
                                                            : null
                                                    }

                                                </td>
                                                <td>
                                                    {
                                                        prd.ModelFile ?
                                                            <button className="btn" data-bs-toggle="modal" data-bs-target="#3dPopUp" onClick={() => this.showPopUp3d(prd.ModelFile)}>
                                                                <img width={64} src={ucdIcon} />
                                                            </button>
                                                            // <button className="btn" onClick={() => this.showPopUp3d(prd.ModelFile)}>
                                                            //     <img width={64} src={stepIcon} />
                                                            // </button>
                                                            : null
                                                    }

                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : null
                }
                </div>

                <div className="modal fade" id="schemaPopUp" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('tabloBaslikPdfOnizleme')} {this.state.OrjinalKodu}</h4>
                            </div>
                            <div className="modal-body">
                                <img width={650} src={this.state.imgSchema}></img>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning btn-sm" data-bs-dismiss="modal" >{t('butonKapat')}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade shadow" id="imgPopUp" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('tabloBaslikResim')} {this.state.OrjinalKodu}</h4>
                            </div>
                            <div className="modal-body">
                                <img className="mx-auto d-block img-fluid" src={this.state.imgProduct}></img>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning btn-sm" data-bs-dismiss="modal" >{t('butonKapat')}</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="3dPopUp" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('menu3dView')}</h4>
                            </div>
                            <div className="modal-body">
                                <IFrameWindow productUrl={this.state.productUrl}></IFrameWindow>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-warning btn-sm" data-bs-dismiss="modal" >{t('butonKapat')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}