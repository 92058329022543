import { toast, ToastContainer } from "react-toastify";
import { useReducer, useState } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { AuthData } from "../../auth/AuthWrapper";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from "i18next";

export const Login = () => {

    var ePosta = "";
    if (localStorage.getItem('eposta') === null) { ePosta = "" } else { ePosta = localStorage.getItem('eposta') }
    const navigate = useNavigate();
    const { login } = AuthData();
    const [errorMessage, setErrorMessage] = useState(null)
    const [formData, setFormData] = useReducer((formData, newItem) => { setErrorMessage(""); return ({ ...formData, ...newItem }) }, { userName: ePosta, password: "" })

    const doLogin = async () => {
        try {
            await login(formData.userName, formData.password)
            navigate("/")
        } catch (error) {
            toast.warning(error)
            //setErrorMessage(error)
        }
    }

    return (
        <div className="row">
            <div className="offset-lg-3 col-lg-6" style={{ marginTop: '100px' }}>
                <div className="card">
                    <div className="card-header">                        
                        <h2>{t('baslikGiris')} </h2>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <label>{t('formUyelikEposta')} <span className="errmsg">*</span></label>
                            <input value={formData.userName} onChange={(e) => setFormData({ userName: e.target.value })} className="form-control" type="text"></input>
                        </div>
                        <div className="form-group">
                            <label>{t('formUyelikSifre')} <span className="errmsg">*</span></label>
                            <input value={formData.password} onChange={(e) => setFormData({ password: e.target.value })} type="password" className="form-control"></input>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button onClick={doLogin} className="btn btn-primary me-2">{t('butonGiris')}</button>
                        <Link className="btn btn-primary me-2" to={'/forgotpassword'}>{t('butonSifremiUnuttum')}</Link>
                        <Link className="btn btn-success" to={'/addmember'}>{t('butonYeniKullanici')}</Link>
                    </div>
                    <div>
                        {
                            errorMessage ?
                                <div className="error">{errorMessage}</div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}