
export const variables = {

    // API_URL: 'http://192.168.1.11:5143/api/',
    // FILE_URL: 'http://192.168.1.11:5143/Dosyalar/'
    API_URL: process.env.REACT_APP_API_URL,
    FILE_URL: process.env.REACT_APP_FILE_URL,
    IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
    SCHEMA_URL: process.env.REACT_APP_SCHEMA_URL

}