import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';


export const About = () => {
    const { t, i18n } = useTranslation()
    return (
        <div className="page">
            <div className="container">
                <h2>{t('baslikHakkimizda')}</h2>
                <p>{t('metinHakkimizda1')}</p>
                <p>{t('metinHakkimizda2')}</p>
                <div className="logo">
                    <img src={t('logoKama')} alt="" />
                </div>
            </div>
        </div>

    )
}