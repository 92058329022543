import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { variables } from '../../config/Variables';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from "i18next";

export const AddMember = () => {

    const handleSubmit = (e) => {

        e.preventDefault();
        let regobj = { UserId, FirmaAdi, Bolum, AdiSoyadi, Eposta, Sifre, Telefon, WebSitesi, MailDogrulama, ActivationKey, Rol, Language }
        //console.log(regobj)
        if (IsValidate()) {
            //console.log(regobj);
            fetch(variables.API_URL + 'user/addmember', {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'content-type': 'application/json' },
                body: JSON.stringify(regobj)
            })
                .then(res => res.json())
                .then((res) => {
                    console.log(res)

                    if (res == "OK") {
                        toast(t('mesajUyelikBasarili'))
                        navigate('/login')
                    } else {
                        toast(res)
                    }
                }).catch((err) => {
                    toast.error(t('mesajKayitYapilamiyor') + err.message)
                });
        }
    }

    const [UserId, idChange] = useState(0);
    const [Eposta, EpostaChange] = useState("");
    const [Sifre, sifreChange] = useState("");
    const [AdiSoyadi, adSoyadChange] = useState("");
    const [FirmaAdi, FirmaAdiChange] = useState("");
    const [Bolum, bolumChange] = useState("");
    const [WebSitesi, webSitesiChange] = useState("");
    const [Telefon, telefonChange] = useState("");
    const [MailDogrulama, mailDogrulamaChange] = useState(0)
    const [ActivationKey, setaKey] = useState("")
    const [Rol, setRol] = useState("2")
    const [Language, setLanguage] = useState(i18n.language)

    const editMode = sessionStorage.getItem("userId") ? true : false;

    const navigate = useNavigate();
    const IsValidate = () => {
        let isProceed = true;
        let errorMessage = t('mesajUyelikBosAlanlar');
        if (Eposta === null || Eposta === '') {
            isProceed = false;            
            errorMessage += t('formUyelikEposta') + ' ';
        }

        if (Sifre === null || Sifre === '') {
            isProceed = false;            
            errorMessage += t('formUyelikSifre') + ' ';
        }

        if (AdiSoyadi === null || AdiSoyadi === '') {
            isProceed = false;
            errorMessage += t('formUyelikAdSoyad') + ' ';
        }

        if (Sifre.length > 12) {
            isProceed = false;
            errorMessage += t('mesajSifreUzunluguUyari');
        }

        if (Sifre.length < 6) {
            isProceed = false;
            errorMessage += t('mesajSifreUzunluguUyari');
        }
        if (FirmaAdi.length < 3) {
            isProceed = false;
            errorMessage += t('mesajFirmaAdiUyari');
        }

        if (Telefon.length < 7) {
            isProceed = false;
            errorMessage += t('mesajTelefonUyari');
        }

        if (!isProceed) {
            toast.warning(errorMessage)
        } else {
            if (/.+@.+\.[A-Za-z]+$/.test(Eposta)) {
            } else {
                isProceed = false;
                toast.warning(t('mesajEPostaUyari'))
            }
        }
        return isProceed;
    }


    return (
        <div>
            <div className="offset-lg-3 col-lg-6">
                <form className="container" onSubmit={handleSubmit}>
                    <div className="card m-5">
                        <div className="card-header">
                            {                                
                                editMode ? <h1>{t('formUyelikBaslikDuzenle')}</h1> : <h1>{t('formUyelikBaslikYeni')}</h1>
                            }
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t('formUyelikEposta')} <span className="errmsg">*</span></label>
                                        <input value={Eposta} onChange={e => EpostaChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t('formUyelikSifre')} <span className="errmsg">*</span></label>
                                        <input value={Sifre} onChange={e => sifreChange(e.target.value)} type="password" className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('formUyelikAdSoyad')} <span className="errmsg">*</span></label>
                                        <input value={AdiSoyadi} onChange={e => adSoyadChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('formUyelikFirmaAdi')}<span className="errmsg">*</span></label>
                                        <input value={FirmaAdi} onChange={e => FirmaAdiChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('formUyelikBolum')} <span className="errmsg"></span></label>
                                        <input value={Bolum} onChange={e => bolumChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('formUyelikWebSitesi')} </label>
                                        <input value={WebSitesi} onChange={e => webSitesiChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t('formUyelikTelefon')} <span className="errmsg">*</span></label>
                                        <input value={Telefon} onChange={e => telefonChange(e.target.value)} className="form-control" placeholder='(212) 123-45-67'></input>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="card-footer">

                            <button type="submit" className="btn btn-primary me-2 btn-sm">{t('butonKayitOl')} </button>
                            <Link to={'/Home'} className="btn btn-danger btn-sm">{t('butonVazgec')} </Link>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    );

}

