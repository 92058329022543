import { Link, useNavigate } from "react-router-dom";
import { variables } from '../../config/Variables'
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from "i18next";

export const ForgotPassword = () => {

    const [ePosta, ePostaUpdate] = useState('');
    const adreseGit = useNavigate();
    useEffect(() => {
        sessionStorage.clear();
    }, []);

    const validate = () => {
        let result = true;
        if (ePosta === '' || ePosta === null) {
            result = false;
            toast.warning(t('mesajEpostaEksik'));
        }

        return result
    }

    const ProceedLogin = (e) => {
        e.preventDefault();
        if (validate()) {
            //console.log("proceed")
            let epostaObject = { "eposta": ePosta, "sifre": "", "Rol": "2" }
            console.log(epostaObject)
            fetch(variables.API_URL + 'login/forgotpassword', {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(epostaObject)
            }).then((res) => {
                return res.json();
            }).then((resp) => {
                //console.log(resp)
                if (resp == "OK") {
                    toast.success(t('mesajSifremiUnuttumMail'))
                    adreseGit('/login')
                } else {
                    toast.warning(resp)
                }
            }).catch((err) => {
                toast.error(t('mesajSunucuTarafiHatasi') + err.message);
            });
        }
    }

    return (
        <div className="row">
            <div className="offset-lg-3 col-lg-6" style={{ marginTop: '100px' }}>
                <form onSubmit={ProceedLogin} className="container">
                    <div className="card">
                        <div className="card-header">
                            <h2>{t('baslikSifremiUnuttum')}</h2>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label>{t('formUyelikEposta')} <span className="errmsg">*</span></label>
                                <input value={ePosta} onChange={e => ePostaUpdate(e.target.value)} className="form-control"></input>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary me-2">{t('butonDevam')}</button>
                            <Link className="btn btn-success" to={'/login'}>{t('butonGeri')}</Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
