import { t } from "i18next";
function  IFrameWindow(props) {
    
    //const productUrl = this.props.productUrl;    

    return (
        <div style={{height:"100%", margin:0}}>
            <iframe src={props.productUrl} width="100%" height="100%" sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
                allowtransparency="true"></iframe>
        </div>
    )
}
export default IFrameWindow;
