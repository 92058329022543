import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { variables } from '../../config/Variables';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from "i18next";

export const MyAccount = () => {

    const handleSubmit = (e) => {

        e.preventDefault();
        let regobj = { UserId, FirmaAdi, Bolum, AdiSoyadi, Eposta, Sifre, Telefon, WebSitesi, MailDogrulama, ActivationKey, Rol, Language }
        //console.log(regobj)
        if (IsValidate()) {
            //console.log(regobj);
            fetch(variables.API_URL + 'user', {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'authorization': 'Bearer ' + sessionStorage.getItem('jwtKey') },
                body: JSON.stringify(regobj)
            })
                .then(res => res.json())
                .then((res) => {
                    console.log(res)

                    if (res == "OK") {
                        if (editMode) {
                            toast(t('mesajBilgilerinizGuncellendi'))
                            navigate('/')
                        }
                        else {
                            toast(t('mesajUyelikBasarili'))
                            navigate('/login')
                        }

                    } else {
                        toast(res)
                    }
                }).catch((err) => {
                    toast.error(t('mesajKayitYapilamiyor') + err.message)
                });
        }
    }


    const [UserId, idChange] = useState(0);
    const [Eposta, EpostaChange] = useState("");
    const [Sifre, sifreChange] = useState("");
    const [AdiSoyadi, adSoyadChange] = useState("");
    const [FirmaAdi, FirmaAdiChange] = useState("");
    const [Bolum, bolumChange] = useState("");
    const [WebSitesi, webSitesiChange] = useState("");
    const [Telefon, telefonChange] = useState("");
    const [MailDogrulama, mailDogrulamaChange] = useState(0)
    const [ActivationKey, setaKey] = useState("")
    const [Rol, rolChange] = useState(2);
    const [Language, setLanguage] = useState(i18n.language)

    const editMode = sessionStorage.getItem("activationKey") ? true : false;

    useEffect(() => {
        const key = sessionStorage.getItem("activationKey");
        if (editMode) { fillForm() }
    }, []);


    // function fillForm() {
    //     fetch(variables.API_URL + 'user/GetUserFromKey', {
    //         method: 'POST',
    //         headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'authorization': 'Bearer ' + sessionStorage.getItem('jwtKey') },
    //         body: JSON.stringify({
    //             ActivationCode: sessionStorage.getItem("activationKey")
    //         })
    //     })
    //         .then(res => res.json())
    //         .then((res) => {
    //             //console.log(res[0].AdiSoyadi)
    //             EpostaChange(res[0].Eposta);
    //             sifreChange(res[0].Sifre);
    //             adSoyadChange(res[0].AdiSoyadi);
    //             FirmaAdiChange(res[0].FirmaAdi);
    //             bolumChange(res[0].Bolum);
    //             webSitesiChange(res[0].WebSitesi);
    //             telefonChange(res[0].Telefon);
    //             setaKey(sessionStorage.getItem("activationKey"))
    //         })
    // }

    function fillForm() {

        fetch(variables.API_URL + 'user/GetUserFromKey', {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'content-type': 'application/json', 'authorization': 'Bearer ' + sessionStorage.getItem('jwtKey') },
            body: JSON.stringify({
                ActivationCode: sessionStorage.getItem("activationKey")
            })
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error(response.status)
                }
                else {
                    return response.json();
                }
                //return Promise.reject(response);
            })
            .then(data => {
                EpostaChange(data[0].Eposta);
                sifreChange(data[0].Sifre);
                adSoyadChange(data[0].AdiSoyadi);
                FirmaAdiChange(data[0].FirmaAdi);
                bolumChange(data[0].Bolum);
                webSitesiChange(data[0].WebSitesi);
                telefonChange(data[0].Telefon);
                setaKey(sessionStorage.getItem("activationKey"));
            })
            .catch((response) => {
                toast.warning(t('mesajOturumZamanAsimi'))
                const link = document.createElement('a');
                link.href = "/login";
                document.body.appendChild(link);
                link.click();
            })
    }

    const navigate = useNavigate();
    const IsValidate = () => {
        let isProceed = true;
        let errorMessage = t('mesajUyelikBosAlanlar');
        if (Eposta === null || Eposta === '') {
            isProceed = false;
            errorMessage += t('formUyelikEposta') + ' ';
        }

        if (Sifre === null || Sifre === '') {
            isProceed = false;
            errorMessage += t('formUyelikSifre') + ' ';
        }

        if (AdiSoyadi === null || AdiSoyadi === '') {
            isProceed = false;
            errorMessage += t('formUyelikAdSoyad') + ' ';
        }

        if (Sifre.length > 12) {
            isProceed = false;
            errorMessage += t('mesajSifreUzunluguUyari') + ' ';
        }

        if (Sifre.length < 6) {
            isProceed = false;
            errorMessage += t('mesajSifreUzunluguUyari') + ' ';
        }
        if (FirmaAdi.length < 3) {
            isProceed = false;
            errorMessage += t('mesajFirmaAdiUyari') + ' ';
        }

        if (Telefon.length < 7) {
            isProceed = false;
            errorMessage += t('mesajTelefonUyari') + ' ';
        }

        if (!isProceed) {
            toast.warning(errorMessage)
        } else {
            if (/.+@.+\.[A-Za-z]+$/.test(Eposta)) {
            } else {
                isProceed = false;
                toast.warning(t('mesajEPostaUyari'))
            }
        }
        return isProceed;
    }


    return (
        <div>
            <div className="offset-lg-3 col-lg-6">
                <form className="container" onSubmit={handleSubmit}>
                    <div className="card m-5">
                        <div className="card-header">
                            {
                                editMode ? <h1>{t('formUyelikBaslikDuzenle')}</h1> : <h1>{t('formUyelikBaslikYeni')}</h1>
                            }
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t('formUyelikEposta')} <span className="errmsg">*</span></label>
                                        <input value={Eposta} onChange={e => EpostaChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t('formUyelikSifre')} <span className="errmsg">*</span></label>
                                        <input value={Sifre} onChange={e => sifreChange(e.target.value)} type="password" className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('formUyelikAdSoyad')} <span className="errmsg">*</span></label>
                                        <input value={AdiSoyadi} onChange={e => adSoyadChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('formUyelikFirmaAdi')} <span className="errmsg">*</span></label>
                                        <input value={FirmaAdi} onChange={e => FirmaAdiChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('formUyelikBolum')} <span className="errmsg"></span></label>
                                        <input value={Bolum} onChange={e => bolumChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t('formUyelikWebSitesi')} </label>
                                        <input value={WebSitesi} onChange={e => webSitesiChange(e.target.value)} className="form-control"></input>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>{t('formUyelikTelefon')} <span className="errmsg">*</span></label>
                                        <input value={Telefon} onChange={e => telefonChange(e.target.value)} className="form-control" placeholder='(212) 123-45-67'></input>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="card-footer">

                            {
                                editMode ?
                                    <button type="submit" className="btn btn-primary me-2 btn-sm">{t('butonGuncelle')}</button> :
                                    <button type="submit" className="btn btn-primary me-2 btn-sm">{t('butonKayitOl')}</button>
                            }

                            {editMode ?
                                <Link to={'/'} className="btn btn-danger btn-sm">{t('butonVazgec')}</Link> :
                                <Link to={'/login'} className="btn btn-danger btn-sm">{t('butonVazgec')}</Link>
                            }


                        </div>
                    </div>
                </form>
            </div>

        </div>
    );
}


