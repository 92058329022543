import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from "i18next";

export const RenderHeader = () => {

    return (
        <div className="header">
            <div className="logo">
                <img onClick={() => { window.location.href = '/' }} src="kama-3d-logo.png" alt="" />
            </div>
            <h1>{t('baslikAnaSayfa')}</h1>
        </div>
    )
}