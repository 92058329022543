import { Link, Route, Routes } from 'react-router-dom'
import { AuthData } from '../../auth/AuthWrapper'
import { nav } from './navigation'
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from "i18next";

export const RenderRoutes = () => {

    const { user } = AuthData()
    return (
        <Routes>
            {nav.map((r, i) => {
                if (r.isPrivate && user.isAuthenticated) {
                    return <Route key={i} path={r.path} element={r.element} />
                } else if (!r.isPrivate) {
                    return <Route key={i} path={r.path} element={r.element} />
                } else return false
            })}
        </Routes>
    )
}

export const RenderMenu = () => {

    const changeLang = async lang => {
        await i18n.changeLanguage(lang)
        localStorage.setItem('siteLang', lang);        
        window.location.href='/'
    }

    const { user, logout } = AuthData()
    const MenuItem = ({ r }) => {
        return (
            <div className='menuItem'><Link to={r.path}>{r.name}</Link></div>
        )
    }
    return (
        <div className='menu'>
            {nav.map((r, i) => {
                if (!r.isPrivate && r.isMenu) {
                    return (
                        <MenuItem key={i} r={r} />
                    )
                } else if (user.isAuthenticated && r.isMenu) {
                    return (
                        <MenuItem key={i} r={r} />
                    )
                } else return false
            })}

            {
                user.isAuthenticated ?
                    <div className='menuItem'><Link to={'#'} onClick={logout}>{t('butonCikis')}</Link></div>
                    :
                    <>
                        <div className='menuItem'><Link to={'addmember'}>{t('butonYeniKullanici')}</Link></div>
                        <div className='menuItem'><Link to={'forgotpassword'}>{t('butonSifremiUnuttum')}</Link></div>
                        <div className='menuItem'><Link to={'login'}>{t('butonGiris')}</Link></div>
                    </>
            }

            <a href="#">
            <img onClick={() => {
                changeLang('tr')
                }} src="flag-tr.png" alt="" /></a>&nbsp;

            <a href="#"><img onClick={() => {
                changeLang('en')
            }} src="flag-en.png" alt=""  />
            </a>

        </div>
    )
}