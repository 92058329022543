import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { t } from "i18next";

export const Home = () => {
    const navigate = useNavigate()

    useEffect(() => {
        if (sessionStorage.getItem("jwtKey") === null) {
            navigate("/login")
        }
    }, []);


    return (
        <div className="page">
            <div className="jumbotron">
                <h1 className="display-4">{t('baslikHosgeldiniz')}</h1>
                <p className="lead">{t('mesajHosgeldiniz')}</p>

                <p>{t('mesajHosgeldiniz1')}</p>

            </div>
        </div>
    )
}