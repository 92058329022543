import { createContext, useContext, useState, useEffect } from "react";
import { RenderHeader } from '../components/structure/Header';
import { RenderMenu, RenderRoutes } from '../components/structure/RenderNavigation';
import { variables } from '../config/Variables'
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";
import { t } from "i18next";

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = () => {

    const [user, setUser] = useState({ name: '', isAuthenticated: false })
    const [sayfa, setSayfa] = useState(window.location.href)

    useEffect(() => {
        if (sessionStorage.getItem("jwtKey")) {
            if (sessionStorage.getItem("jwtKey").length > 0) {
                setUser({ name: sessionStorage.getItem("ePosta"), isAuthenticated: true })
                //console.log("sesison işlemi tetiklendi.")
            }

        }
    }, [AuthData]);

    const login = async (userName, password) => {
        // Api gelecek ... geldi;

        let loginObject = { "eposta": userName, "sifre": password, "rol": "2" }
        //console.log(loginObject);

        await fetch(variables.API_URL + 'login', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(loginObject)
        }).then((res) => {
            return res.json();
        }).then((resp) => {
            if (Object.keys(resp.jwtKey).length == 0) {
                sessionStorage.setItem("jwtKey", "")
                sessionStorage.setItem("ePosta", "")
                sessionStorage.setItem("activationKey", "")
                sessionStorage.setItem("userId", "")
            } else {
                sessionStorage.setItem("jwtKey", resp.jwtKey)
                sessionStorage.setItem("ePosta", resp.eposta)
                sessionStorage.setItem("activationKey", resp.activationKey)
                sessionStorage.setItem("userId", resp.userId)
                localStorage.setItem("eposta", resp.eposta)
            }
        })

        return new Promise((resolve, reject) => {
            /* console.log("jwtKEY:")
             console.log(sessionStorage.getItem("jwtKey"))
             */
            if (sessionStorage.getItem("jwtKey").length > 0) {
                setUser({ name: sessionStorage.getItem("ePosta"), isAuthenticated: true })
                resolve("success")
            } else {
                reject(t('mesajGirisHatali'))
            }
        })
    }

    const logout = () => {
        sessionStorage.clear()
        setUser({ ...user, isAuthenticated: false })
    }

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            <div>
                <RenderHeader />
                <RenderMenu />
                <RenderRoutes />
            </div>
        </AuthContext.Provider>
    )

}